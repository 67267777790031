import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useGetCurrentUrl } from "@/hooks";
import { useSearchForm } from "@/hooks/search";
import {
  CaretDown,
  Heart,
  List,
  MagnifyingGlass,
  SignIn,
  X,
} from "@phosphor-icons/react";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../AppContext";
import navLogo from "../../assets/images/nav-logo.svg";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Input } from "../ui/input";

import { loginPopup, registerPopup } from "@/Atoms";
import { useAtom } from "jotai";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { ArrowRight, CalendarCheck } from "@phosphor-icons/react/dist/ssr";

function Navbar({ user, logout }) {
  const [openLoginPopup, setOpenLoginPopup] = useAtom(loginPopup);
  const [openRegisterPopup, setOpenRegisterPopup] = useAtom(registerPopup);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleProfile, setToggleProfile] = useState(false);
  const { keyword, setKeyword, doSearch } = useSearchForm();

  const appContext = useContext(AppContext);
  const topics = appContext.state.topic_list || [];
  const dropdown = useRef(null);
  const searchInput = useRef(null);
  const router = useRouter();

  const menus = [
    {
      name: "Special Reports",
      url: "/reports",
    },
    {
      name: "Topics",
      children: topics.map((topic) => ({
        name: topic.name,
        url: `/topics/${topic.slug}`,
      })),
      url: "/topics",
    },
    {
      name: "Podcasts",
      url: "/podcasts-masterclasses",
    },
    {
      name: "Opinions",
      url: "/opinions",
    },
    {
      name: "Videos",
      url: "/videos",
    },
    {
      name: "Infobytes",
      url: "/infobytes",
    },
    {
      name: "Spotlights",
      url: "/spotlights",
    },
    {
      name: "Articles",
      url: "/articles",
    },
    {
      name: "News",
      url: "/news",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = document.documentElement.scrollTop;
      const isVisible = prevScrollPos > currentScrollPos;

      setPrevScrollPos(currentScrollPos);
      setVisible(isVisible);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  const currentUrl = useGetCurrentUrl();

  useEffect(() => {
    setToggleMenu(false);
    setToggleProfile(false);
  }, [currentUrl]);

  useEffect(() => {
    // only add the event listener when the dropdown is opened
    if (!toggleProfile) return;
    function handleClick(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setToggleProfile(false);
      }
    }
    window.addEventListener("click", handleClick);
    // clean up
    return () => window.removeEventListener("click", handleClick);
  }, [toggleProfile]);

  function setFocus(event) {
    event.preventDefault();
    searchInput.current.focus();
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Command + / (MacOS) or Ctrl + / (Windows) key combination
      if ((event.metaKey || event.ctrlKey) && event.key === "/") {
        setIsSearchOpen(!isSearchOpen);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isSearchOpen]);

  const onSearch = (e) => {
    if (router.pathname == "/search/results") {
      setIsSearchOpen(false);
      doSearch(e);
    } else {
      setIsSearchOpen(false);
      doSearch(e, setIsSearchOpen);
    }
  };

  function DropdownProfile(user) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger className='ml-auto mr-3'>
          <Avatar className='w-8 h-8 ring-1 ring-offset-1 ring-offset-background ring-orange-1/30'>
            <AvatarImage src={user.profile_image_url} />
            <AvatarFallback className='text-xs font-medium uppercase'>
              {user.first_name ? user.first_name[0] : user.name[0]}
              {user.last_name ? user.last_name[0] : ""}
            </AvatarFallback>
          </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent collisionPadding={8}>
          <DropdownMenuLabel className='flex space-x-2.5'>
            <Avatar className='w-9 h-9'>
              <AvatarImage src={user.profile_image_url} />
              <AvatarFallback className='text-xs font-medium uppercase'>
                {user.first_name ? user.first_name[0] : user.name[0]}
                {user.last_name ? user.last_name[0] : ""}
              </AvatarFallback>
            </Avatar>
            <div className='flex flex-col'>
              <span className='text-sm'>
                {user.first_name} {user.last_name}
              </span>
              <span className='text-xs font-normal text-white/40'>
                {user.email}
              </span>
            </div>
          </DropdownMenuLabel>
          <p className='px-2 mt-3 mb-1 text-xs opacity-50'>Account</p>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/profile"}>
              <a className='w-full'>Profile</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/password"}>
              <a className='w-full'>Password</a>
            </Link>
          </DropdownMenuItem>
          <p className='px-2 mt-3 mb-1 text-xs opacity-50'>Content</p>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/preferences"}>
              <a className='w-full'>Preferences</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/bookmarks"}>
              <a className='w-full'>Bookmarks</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/watchlist"}>
              <a className='w-full'>Watchlist</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/report-history"}>
              <a className='w-full'>Report History</a>
            </Link>
          </DropdownMenuItem>
          <p className='px-2 mt-3 mb-1 text-xs opacity-50'>Subscription</p>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/plan"}>
              <a className='w-full'>My Plan</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/payment"}>
              <a className='w-full'>Payment</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/billing"}>
              <a className='w-full'>Billing</a>
            </Link>
          </DropdownMenuItem>
          <p className='px-2 mt-3 mb-1 text-xs opacity-50'>Startup</p>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/startup"}>
              <a className='w-full'>My Startup</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onSelect={logout}>Log out</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  return (
    <div className='sticky top-0 z-50'>
      <div className='flex items-center justify-center gap-3 text-sm text-white shadow-md top-28 h-9 bg-gradient-to-l from-red-500 to-purple-600'>
        <div className='flex items-center gap-1.5 font-medium'>
          <CalendarCheck weight='bold' />
          <span>Book a free demo with us</span>
        </div>
        <Link
          href='https://calendly.com/lucy-viqk/free-onboarding-demo'
          target='_blank'
          className='inline-flex items-center gap-1.5 px-2.5 py-1.5 text-xs font-medium text-white bg-black rounded-md shadow-md'
        >
          Book Now
          <ArrowRight weight='bold' />
        </Link>
      </div>
      <div
        className={`bg-background/90 backdrop-blur-lg transition-transform duration-500`}
      >
        <div className='hidden h-12 border-b bg-background/80 lg:block border-white/10'>
          <div className='container flex items-center h-full pt-1 my-auto space-x-5 lg:pt-0'>
            <AlertDialog open={isSearchOpen} onOpenChange={setIsSearchOpen}>
              <AlertDialogTrigger asChild>
                <div className='flex items-center flex-1 text-sm font-normal opacity-50'>
                  <MagnifyingGlass
                    weight='regular'
                    className='mr-2 text-base'
                  />
                  Search...
                  {/* <span className='inline-block ml-4 space-x-1 text-xs'>
                  <span className='inline-block px-1.5 py-0.5 border rounded-md border-white/10 shadow-sm shadow-white/10 bg-white/10'>
                    {typeof window !== "undefined" &&
                    window.navigator.userAgent.indexOf("Mac OS") != -1
                      ? "⌘"
                      : "Ctrl"}
                  </span>
                  <span>+</span>
                  <span className='inline-block px-1.5 py-0.5 border rounded-md border-white/10 shadow-sm shadow-white/10 bg-white/10'>
                    /
                  </span>
                </span> */}
                </div>
              </AlertDialogTrigger>
              <AlertDialogContent
                onOpenAutoFocus={(event) => setFocus(event)}
                className='flex items-center p-0 !bg-background/80 h-screen !rounded-none border-0 max-w-none'
              >
                <form onSubmit={onSearch} className='container w-full'>
                  <p className='mb-3 text-lg opacity-30'>
                    Feeling lost? Simply search using keywords, report names,
                    news, or anything!
                  </p>
                  <div className='flex items-center justify-between pb-4 border-b-2 border-white/10'>
                    <div className='flex items-center flex-1'>
                      <MagnifyingGlass className='flex-shrink-0 mr-4 text-6xl translate-y-2 text-white/20' />
                      <Input
                        ref={searchInput}
                        placeholder='Type here...'
                        defaultValue={keyword}
                        onInput={(evt) => setKeyword(evt.target.value)}
                        className='h-auto w-full p-0 text-7xl placeholder:text-white/10 tracking-tight font-medium font-display border-0 !shadow-none !bg-transparent focus-visible:border-0 focus-visible:ring-0 focus-visible:outline-0 focus-visible:ring-offset-0'
                      />
                    </div>
                    <div className='w-auto ml-5 text-xs translate-y-2 text-white/30'>
                      <span className='inline-block px-1.5 py-0.5 mr-2 border rounded-md shadow-sm shadow-white/10 bg-white/10'>
                        Enter
                      </span>
                      to search
                    </div>
                  </div>
                  <div className='flex items-start justify-between'>
                    <div className='mt-8 text-xs text-white/30'>
                      <span className='inline-block px-1.5 py-0.5 mr-2 border rounded-md shadow-sm shadow-white/10 bg-white/5'>
                        Esc
                      </span>
                      or
                      {/* <span className='inline-block mx-2 space-x-1'>
                      <span className='inline-block px-1.5 py-0.5 border rounded-md shadow-sm shadow-white/10 bg-white/5'>
                        {typeof window !== "undefined" &&
                        window.navigator.userAgent.indexOf("Mac OS") != -1
                          ? "⌘"
                          : "Ctrl"}
                      </span>
                      <span>+</span>
                      <span className='inline-block px-1.5 py-0.5 border rounded-md shadow-sm shadow-white/10 bg-white/5'>
                        /
                      </span>
                    </span> */}
                      again to close
                    </div>
                  </div>
                  <Input type='submit' className='hidden' />
                </form>
              </AlertDialogContent>
            </AlertDialog>
            {user ? (
              <div className='flex items-center space-x-5'>
                <Link legacyBehavior href={"/account/startup"} passHref>
                  <a className='text-sm font-medium duration-300 whitespace-nowrap hover:opacity-70'>
                    My Startup
                  </a>
                </Link>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <button
                        type='button'
                        onClick={() => router.push("/account/watchlist")}
                        className='inline-block text-sm font-medium duration-300 whitespace-nowrap hover:opacity-70'
                      >
                        <Heart weight='bold' className='text-xl' />
                      </button>
                    </TooltipTrigger>
                    <TooltipContent>Watchlist</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <div className=''>
                  <DropdownProfile {...user} />
                </div>
              </div>
            ) : (
              <div className='flex items-center justify-center'>
                <button
                  type='button'
                  onClick={() => setOpenRegisterPopup(true)}
                >
                  <a className='hidden pr-3 mr-3 text-sm text-white border-r lg:block border-white/10'>
                    <span>Register</span>
                  </a>
                </button>
                <button
                  type='button'
                  onClick={() => setOpenLoginPopup(true)}
                  className='inline-flex items-center text-sm font-medium'
                >
                  <SignIn weight='bold' className='mr-1.5 lg:opacity-60' />
                  <span>Login</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className='w-full h-16 text-white duration-300 ease-out bg-[#161617]/80 lg:border-b lg:border-white/5'>
          <div className='container flex items-center w-full h-full lg:justify-between'>
            <div className='flex items-center flex-1 space-x-8'>
              <div className='inline-flex items-center'>
                <Link legacyBehavior href={"/"} passHref>
                  <a className='inline-block relative w-auto h-10 2xl:h-12 aspect-[189/48]'>
                    <Image
                      src={navLogo.src}
                      alt='Lucidity Insights'
                      layout='fill'
                      objectFit='cover'
                    />
                  </a>
                </Link>
              </div>
              <div className='flex-col flex-wrap items-center hidden h-full gap-3 pt-4 my-auto lg:flex lg:pt-0 2xl:gap-6 lg:flex-nowrap lg:flex-row gap-y-1'>
                {menus.map((menu, index) =>
                  !menu.children ? (
                    <Link legacyBehavior href={menu.url} passHref key={index}>
                      <a className='text-xs 2xl:text-sm font-medium flex items-center px-4 py-2.5 lg:p-0 rounded-lg bg-white/5 lg:bg-transparent w-full lg:w-auto whitespace-nowrap'>
                        {menu.name}
                      </a>
                    </Link>
                  ) : (
                    <DropdownMenu key={index}>
                      <DropdownMenuTrigger className='inline-flex items-center text-xs font-medium 2xl:text-sm'>
                        {menu.name}
                        <CaretDown weight='bold' className='ml-1' />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        {menu.children.map((child, index) => (
                          <DropdownMenuItem key={index}>
                            <Link legacyBehavior href={child.url} passHref>
                              <a className='w-full'>{child.name}</a>
                            </Link>
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )
                )}
              </div>
            </div>
            <div className='hidden lg:flex items-center justify-end space-x-1 2xl:space-x-1.5'>
              <Link legacyBehavior href={"/startups"} passHref>
                <a className='text-xs 2xl:text-sm font-medium flex items-center px-2.5 py-2.5 2xl:px-3 lg:py-1 rounded lg:bg-gradient-to-r from-orange-500 to-yellow-600 w-full lg:w-auto whitespace-nowrap'>
                  Startup Database
                </a>
              </Link>
              <Link legacyBehavior href={"/investors"} passHref>
                <a className='text-xs 2xl:text-sm font-medium flex items-center px-2.5 py-2.5 2xl:px-3 lg:py-1 rounded lg:bg-gradient-to-r from-purple-500 to-red-500 w-full lg:w-auto whitespace-nowrap -ml-4'>
                  Investor Database
                </a>
              </Link>
              <Link legacyBehavior href={"/pricing"} passHref>
                <a className='whitespace-nowrap font-medium text-background w-full lg:w-auto px-2.5 py-2.5 2xl:px-3 lg:py-1 rounded bg-white text-xs 2xl:text-sm justify-center inline-flex space-x-1.5 items-center'>
                  ⚡️ Plans
                </a>
              </Link>
            </div>
            <div className='flex items-center ml-auto'>
              {!user && (
                <div onClick={() => setOpenRegisterPopup(true)}>
                  <a className='mr-3 text-sm font-medium lg:hidden whitespace-nowrap'>
                    Register
                  </a>
                </div>
              )}
              {!user ? (
                <div className='flex items-center mr-2 space-x-2.5 lg:hidden ml-4'>
                  <div className='flex items-center justify-center ml-auto'>
                    <div onClick={() => setOpenLoginPopup(true)}>
                      <a className='inline-flex items-center justify-center w-8 h-8 text-lg rounded-md bg-white/5'>
                        <SignIn
                          color='#ffffff'
                          weight='bold'
                          className='opacity-60'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='flex items-center ml-auto mr-4 space-x-4 lg:hidden'>
                  <button
                    type='button'
                    onClick={() => router.push("/account/watchlist")}
                    className='inline-block text-sm font-medium duration-300 whitespace-nowrap hover:opacity-70'
                  >
                    <Heart weight='bold' className='text-2xl' />
                  </button>
                  <DropdownProfile {...user} />
                </div>
              )}
              <button
                onClick={toggleNav}
                className='lg:hidden'
                aria-label='Toggle Navigation Menu'
              >
                {toggleMenu ? (
                  <X size={28} color='#ffffff' weight='bold' />
                ) : (
                  <List size={28} color='#ffffff' weight='bold' />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {toggleMenu && (
          <div className='h-[calc(100vh-4rem)] overflow-y-auto px-4 pt-3 pb-4 text-white lg:hidden'>
            <form onSubmit={doSearch} className='relative mb-3'>
              <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
                <MagnifyingGlass size={16} weight='regular' />
              </div>
              <input
                type='text'
                className='block w-full pl-6 transition-colors duration-200 bg-transparent border-none focus-visible:!outline-none focus-visible:!border-0 placeholder:text-white/30 focus-visible:!ring-0 sm:text-sm'
                placeholder='Search...'
                defaultValue={keyword}
                onInput={(evt) => setKeyword(evt.target.value)}
              />
              <input type='submit' hidden />
            </form>
            <div className='flex flex-col justify-between space-y-1.5'>
              {menus.map((menu, index) =>
                !menu.children ? (
                  <Link legacyBehavior href={menu.url} passHref key={index}>
                    <a className='flex items-center px-4 py-2.5 lg:p-0 rounded-lg bg-white/5 lg:bg-transparent w-full lg:w-auto whitespace-nowrap'>
                      <span className='text-sm font-medium lg:m-0'>
                        {menu.name}
                      </span>
                    </a>
                  </Link>
                ) : (
                  <Accordion
                    type='single'
                    collapsible
                    className='w-full'
                    key={index}
                  >
                    <AccordionItem value='topics' className='border-0'>
                      <AccordionTrigger className='flex !no-underline items-center px-4 py-2.5 lg:p-0 !text-sm rounded-lg bg-white/5 lg:bg-transparent w-full lg:w-auto whitespace-nowrap'>
                        {menu.name}
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className='pl-3 mt-2 space-y-1 border-l border-white/10'>
                          {menu.children.map((child, index) => (
                            <Link
                              legacyBehavior
                              href={child.url}
                              passHref
                              key={index}
                            >
                              <a className='flex items-center w-full px-4 py-2 rounded-lg lg:p-0 bg-white/5 lg:bg-transparent lg:w-auto whitespace-nowrap'>
                                <span className='text-xs font-medium lg:m-0'>
                                  {child.name}
                                </span>
                              </a>
                            </Link>
                          ))}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                )
              )}
              <Link legacyBehavior href={"/startups"} passHref>
                <a className='flex items-center px-4 py-2.5 lg:px-2 lg:py-1 rounded bg-gradient-to-r from-orange-500 to-yellow-500 w-full lg:w-auto whitespace-nowrap'>
                  <span className='text-sm font-medium lg:m-0'>
                    Startup Database
                  </span>
                </a>
              </Link>
              <Link legacyBehavior href={"/investors"} passHref>
                <a className='flex items-center px-4 py-2.5 lg:px-2 lg:py-1 rounded bg-gradient-to-r from-purple-500 to-red-500 w-full lg:w-auto whitespace-nowrap'>
                  <span className='text-sm font-medium lg:m-0'>
                    Investor Database
                  </span>
                </a>
              </Link>
              <Link legacyBehavior href={"/pricing"} passHref>
                <a className='flex items-center px-4 py-2.5 lg:px-2 lg:py-1 rounded text-background bg-white w-full lg:w-auto whitespace-nowrap'>
                  <span className='text-sm font-medium lg:m-0'>Plans</span>
                </a>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
